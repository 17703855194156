<template>
  <div ref="page_box" class="page">
    <div ref="top_box" class="header_boxs">
      <img v-if="!weixin" src="../../assets/back_arrow.png" alt="" class="arrows" @click="goBack()">
      <span v-else></span>
      <span class="daojishi">
        <img src="../../assets/daojishi.png" alt="">
        <span ref="countdownTime" class="countDown"></span>
      </span>
      <span class="card" @click="onAnswerCard">答题卡</span>
    </div>
    <div class="main">
      <template v-for="(item,key) in list">
        <div v-show="testIndex===key" :key="key+'每道题'" class="question-list">
          <div class="question-list-title">
            <span v-show="item.type===1 || item.type==='1'" class="btn_select">
              单选
            </span>
            <span v-show="item.type===2|| item.type==='2'" class="btn_select">
              多选
            </span>
            <span v-show="item.type===3|| item.type==='3'" class="btn_select">
              主观
            </span>
            <span class="question-title" v-html="item.name"></span>
            <span class="question-title2">({{ item.years === 0||item.years === '0' ? '模拟题':item.years+'年真题' }})</span>
          </div>
          <div class="potion_item">
            <van-radio-group v-show="item.type===1 || item.type==='1'" v-model="item.radio">
              <template v-for="(ele,index) in item.solution">
                <van-radio :key="index" :name="ele.mark" :checked-color="color">{{ ele.mark }} {{ ele.name }}</van-radio>
              </template>
            </van-radio-group>
            <van-radio-group v-show="item.type===3 || item.type==='3'" v-model="item.radio">
              <template v-for="(ele,index) in item.solution">
                <van-radio :key="index" :name="ele.mark" :checked-color="color">{{ ele.mark }} {{ ele.name }}</van-radio>
              </template>
            </van-radio-group>
            <van-checkbox-group v-show="item.type===2|| item.type==='2'" v-model="item.checkList">
              <template v-for="(ele,index) in item.solution">
                <van-checkbox :key="index" :name="ele.mark" :checked-color="color">{{ ele.mark }} {{ ele.name }}</van-checkbox>
              </template>
            </van-checkbox-group>
          </div>
          <div class="btn_box ">
            <span v-show="testIndex>0" class="btn_tab btn_item btn_pre" @click="onPre()">
              <van-icon color="#ee2e2e" name="arrow-left" size="20" />
              上一题
            </span>
            <span v-show="testIndex<=0" class="btn_tab btn_item btn_pre">
            </span>
            <span class="btn_item" style=" text-align: center">
              <span style="color: #ee2e2e;font-size: 20px;">{{ key+1 }}</span>/<span style="color:#333333">{{ len }}</span>
            </span>
            <span v-show="testIndex<len-1" class="btn_tab btn_item btn_next" @click="onNext()">
              下一题
              <van-icon color="#ee2e2e" name="arrow" size="20" />
            </span>
            <span v-show="testIndex>=len-1" class="btn_tab btn_item btn_next"></span>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { getNewExerciseList, submitResult } from '@/api/test'
// import md5 from 'js-md5'
export default {
  data() {
    return {
      color: '#ee2e2e',
      countdownTimer: null,
      timer: sessionStorage.getItem('timer') || 7200,
      uid: sessionStorage.getItem('uid') || '',
      part_id: sessionStorage.getItem('part_id') || '',
      result_id: '',
      len: 0,
      list: JSON.parse(sessionStorage.getItem('testInfo')) || [],
      testIndex: sessionStorage.getItem('index') * 1 || 0,
      weixin: this.weixin
    }
  },
  watch: {
    testIndex: {
      handler(newVal, oldVal) {
        sessionStorage.setItem('index', newVal)
      },
      immediate: true
    },
    timer: {
      handler(newVal, oldVal) {
        sessionStorage.setItem('timer', newVal)
      },
      immediate: true
    },
    list: {
      handler(newVal, oldVal) {
        sessionStorage.setItem('testInfo', JSON.stringify(newVal))
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    this.uid = this.$route.query.uid
    this.part_id = this.$route.query.part_id
    sessionStorage.setItem('uid', this.$route.query.uid)
    sessionStorage.setItem('part_id', this.$route.query.part_id)
    this.list.length > 0 || this.getTest()
    if (this.list.length > 0) {
      this.len = this.list.length
      this.countdownTimer = setInterval(this.day, 1000)
    } else {
      this.getTest()
    }
  },
  mounted() {
    this.setiosTop()
    // this.countdownTimer = setInterval(this.day, 1000)
  },
  beforeDestroy() {
    clearInterval(this.countdownTimer)
  },
  methods: {
    setiosTop() {
      const u = navigator.userAgent
      const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
      if (this.weixin) {
        return false
      }
      if (isiOS) {
        if (u.indexOf(' sms_1 ') !== -1) {
          this.$refs.top_box.style.paddingTop = 40 + 'px'
          this.$refs.page_box.style.paddingTop = 84 + 'px'
        } else {
          this.$refs.top_box.style.paddingTop = 20 + 'px'
          this.$refs.page_box.style.paddingTop = 64 + 'px'
        }
        window.webkit.messageHandlers.vue_Fullscreen.postMessage('1')
      }
    },
    getTest() {
      // const time = new Date().getTime()
      // const token = '300123^*)asdf' + time + 'getNewExerciseList' + this.uid
      // const tokenStr = md5(token).toLowerCase()
      // const info = {
      //   uid: this.uid,
      //   part_id: this.part_id,
      //   time: time,
      //   token: tokenStr,
      //   app_type: 1
      // }
      const info = new URLSearchParams()
      const time = new Date().getTime()
      info.append('uid', this.uid)
      info.append('part_id', this.part_id)
      info.append('app_type', 1)
      info.append('time', time)
      getNewExerciseList(info).then(res => {
        const data = res.result
        this.result_id = data.result_id
        sessionStorage.setItem('result_id', data.result_id)
        this.len = data.list.length
        data.list.forEach(element => {
          if (element.type === 1 || element.type === '1') {
            element.radio = ''
          } else if (element.type === 2 || element.type === '2') {
            element.checkList = []
          } else if (element.type === 3 || element.type === '3') {
            element.radio = ''
          }
        })
        // this.list = data.list
        if (this.$route.query.page) {
          this.testIndex = Number(this.$route.query.page)
          this.timer = parseInt(this.$route.query.time)
          this.countdownTimer = setInterval(this.day, 1000)
          this.list = JSON.parse(sessionStorage.getItem('testInfo'))
        } else {
          this.list = data.list
          console.log(1)
          if (!this.countdownTimer) {
            this.countdownTimer = setInterval(this.day, 1000)
          }
        }
      })
    },
    onPre() {
      this.testIndex -= 1
    },
    onNext() {
      this.testIndex += 1
    },
    onAnswerCard() {
      this.$router.push({
        path: 'answerCard',
        query: {
          time: this.timer,
          len: this.len,
          part_id: this.part_id,
          uid: this.uid,
          result_id: this.result_id,
          testIndex: this.testIndex
        }
      })
      clearInterval(this.countdownTimer)
      sessionStorage.setItem('testInfo', JSON.stringify(this.list))
    },
    day() {
      if (this.timer >= 0) {
        var hours = Math.floor(this.timer / 3600)
        var minutes = Math.floor((this.timer % 3600) / 60)
        var seconds = Math.floor(((this.timer % 3600) % 60))
        var str = (hours < 10 ? '0' + hours : hours) + ':' + (minutes < 10 ? '0' + minutes : minutes) + ':' + (seconds < 10 ? '0' + seconds : seconds)
        if (this.$refs.countdownTime) {
          this.$refs.countdownTime.innerText = str
        }
        this.timer -= 1
      } else {
        // this.isTime = true
        console.log('时间到了')
        clearInterval(this.countdownTimer)
        this.submit()
      }
    },
    submit() { // 时间到了自动交卷
      const arr = []
      this.list.forEach(ele => {
        if (ele.type === 1 || ele.type === '1') {
          arr.push(ele.id + '-' + ele.radio)
        } else if (ele.type === 2 || ele.type === '2') {
          arr.push(ele.id + '-' + ele.checkList.join('/'))
        } else if (ele.type === 3 || ele.type === '3') {
          arr.push(ele.id + '-' + ele.radio)
        }
      })
      var params = new URLSearchParams()
      params.append('app_type', 1)
      params.append('part_id', this.part_id)
      params.append('uid', this.uid)
      params.append('all_answer', arr.join(','))
      submitResult(params).then(res => {
        sessionStorage.removeItem('index')
        sessionStorage.removeItem('timer')
        sessionStorage.removeItem('testInfo')
        console.log(res, '交卷')
        if (res.errNo === 0) {
          this.$router.push({
            path: 'testResult',
            query: {
              uid: this.uid,
              result_id: res.result.result_id,
              first: 1
            }
          })
        }
      })
    },
    goBack() {
      sessionStorage.removeItem('testInfo')
      this.$router.push({
        path: '/'
      })
    }
  }

}
</script>

<style lang="scss" scoped>
.page{
    padding-top: 88px;
    .header_boxs{
        box-sizing: content-box;
        height: 88px;
        min-height: 88px;
        width: calc(100% - 60px);
        background: #fff;
        line-height: 88px;
        padding: 0 30px;
        text-align: center;
        font-size: 18PX;
        position: fixed;
        top:0;
        left: 0;
        z-index: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .arrows{
            // position: absolute;
            // left: 30px;
            // top: 50%;
            // margin-top: -20px;
            width: 40px;
            height: 40px;
            vertical-align: middle;
        }
        .daojishi{
            font-weight: 600;
            img{
                width: 48px;
                height: 48px;
                vertical-align: middle;
            }
            .countDown{
              margin-left: 10px;
            }
        }
        .card{
            display: inline-block;
            background: #ee2e2e;
            padding: 10px 20px;
            border-radius: 6px;
            color: #fff;
            font-size: 12PX;
            line-height: 12PX;
        }
    }
}
.main{
    overflow-y: scroll;
    padding: 20px 0 30px;;
    margin-bottom: 100px;
    font-size: 12PX;
    .question-list {
        .btn_select{
            padding: 5px 8px;
            color: #fff;
            background: #ee2e2e;
            border-radius: 5px;
        }
    }
    .question-list-title {
        font-size: 25px;
        padding: 0 30px;
    }
    /deep/.question-title{
        font-size: 16PX;
        font-weight: bold;
        font-stretch: normal;
        line-height: 48px;
        letter-spacing: 0px;
        color: #333333;
        margin-left: 10px;
        p{
          display: inline;
        }
        img{
          width: 100%;
          height: 100%;
        }

    }
    .question-title2{
        font-size: 16PX;
        font-weight: normal;
        letter-spacing: 0px;
        color: #999999;
    }
}
.potion_item{
    padding: 40px 30px 100px;
}
.van-radio,.van-checkbox{
    padding: 40px 0;
    border-bottom: 1px solid #eeee;
    font-size: 16PX;
}
.btn_box{
    font-size: 16PX;
    position: fixed;
    bottom: 0px;
    color: #ee2e2e;
    background: #fff;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100px;
    box-sizing: border-box;
    line-height: 20PX;
    padding: 20px 30px;
    border-top: 1px solid #eee;
    .btn_item{
        display: inline-block;
        // float: left;
        // width: 33%;
        flex: 1;
    }
    .btn_tab{
        display: flex;
        align-items: center;
    }
    .btn_pre{
        border-right: 2px solid #eee;
        text-align: left;
    }
    .btn_next{
        border-left: 2px solid #eee;
        justify-content: flex-end;
    }
}
</style>
